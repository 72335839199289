import { lazy } from 'react';
import React, { useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { AuthContext } from "./contexts/Auth";
import GuardedRoute from "./GuardedRoute";

const Register = lazy(() => import('./views/pages/register/Register'));
const Index = lazy(() => import('./views/pages/home'));
const Page404 = lazy(() => import('./views/pages/page404/Page404'));
const Business = lazy(() => import('./views/pages/home/business'));
const BusinessForm = lazy(() => import('./views/forms/business'));
const GoodNews = lazy(() => import('./views/pages/home/goodnews'));
const GoodNewsForm = lazy(() => import('./views/forms/goodnews'));
const HotelsAndRestaurants = lazy(() => import('./views/pages/home/hotelsandrestaurants'));
const HotelsAndRestaurantsForm = lazy(() => import('./views/forms/hotelsandrestaurants'));
const Religious = lazy(() => import('./views/pages/home/religious'));
const ReligiousForm = lazy(() => import('./views/forms/religious'));
const Resources = lazy(() => import('./views/pages/home/resources'));
const ResourcesForm = lazy(() => import('./views/forms/resources'));
const EditResource = lazy(() => import('./views/resource/EditResource'));
const Events = lazy(() => import('./views/pages/home/events'));
const EventsForm = lazy(() => import('./views/forms/events'));
const FunAndTalents = lazy(() => import('./views/pages/home/funandtalents'));
const FunAndTalentsForm = lazy(() => import('./views/forms/funandtalents'));
const OrganizationAndClubs = lazy(() => import('./views/pages/home/organizationandclub'));
const OrganizationAndClubsForm = lazy(() => import('./views/forms/organizationandclubs'));
const ProfessionalAndHomeRepairs = lazy(() => import('./views/pages/home/professionalandhomerepairs'));
const ProfessionalAndHomeRepairsForm = lazy(() => import('./views/forms/professionalandhomerepairs'));
const DiscussionAndForum = lazy(() => import('./views/pages/home/discussionandforum'));
const DiscussionAndForumForm = lazy(() => import('./views/forms/discussionandforum'));
const PageNotFound = lazy(() => import('./views/pages/page404/Page404'));
const Testing = lazy(() => import('../src/testing'));


const Users = lazy(() => import('./views/users/Users'));
const User = lazy(() => import('./views/users/User'));
const EditUser = lazy(() => import('./views/users/EditUser'));
const HotelsAndRestaurantsInner = lazy(() => import('./views/pages/inner/hotelsandrestaurants'));
const Businessdetail = lazy(() => import('./views/pages/inner/Businessdetail'));
const ResourceDetail = lazy(() => import('./views/pages/inner/ResourceDetail'));
const EventsDetail = lazy(() => import('./views/pages/inner/EventsDetail'));
const OrganizationAndClubDetail = lazy(() => import('./views/pages/inner/OrganizationDetail'));
const ReligiousDetail = lazy(() => import('./views/pages/inner/ReligiousDetail'));
const ProfessionalDetail = lazy(() => import('./views/pages/inner/ProfessionalDetail'));
const FunAndTalentDetail = lazy(() => import('./views/pages/inner/FunAndTalentDetail'));
const GoodNewsDetail = lazy(() => import('./views/pages/inner/GoodNewsDetail'));


const ForgotPassword = lazy(() => import('./views/pages/auth/ForgotPassword'));
const ResetPassword = lazy(() => import('./views/pages/auth/ResetPassword'));
const ChanageOldPassword = lazy(() => import('./views/pages/auth/ChangeOldPassword'));


const Search = lazy(() => import('./views/pages/search/search'));
const BusinessList = lazy(() => import('./views/business/business'));
const payments = lazy(() => import('./views/payments/payments'));
const EditBusiness = lazy(() => import('./views/business/EditBusiness'));
const GetBusinessListDetails = lazy(() => import('./views/business/GetBusinessListDetails'));


const HotelsList = lazy(() => import('./views/hotels/hotels'));
const EditHotels = lazy(() => import('./views/hotels/EditHotels'));
const Faqs = lazy(() => import('./views/pages/home/faqs'));
const AboutUs = lazy(() => import('./views/pages/home/about'));
const TermsAndConditions = lazy(() => import('./views/pages/home/termsncondition'));
const PrivacyPolicy = lazy(() => import('./views/pages/home/privacypolicy'));
const Cookies = lazy(() => import('./views/pages/home/cookies'));
const Blogs = lazy(() => import('./views/pages/home/blogs'));
const Contact = lazy(() => import('./views/pages/home/contact'));
const GetHotelsListDetails = lazy(() => import('./views/hotels/GetHotelsListDetails'));
const EventsList = lazy(() => import('./views/events/events'));
const GetEventsListDetails = lazy(() => import('./views/events/GetEventsListDetails'));
const DashboardGoodNews = lazy(() => import('./views/news/news'));
const NewsDashList = lazy(() => import('./views/news/news-dash-page'));
const FunList = lazy(() => import('./views/fun/fun'));
const GetFunList = lazy(() => import('./views/fun/GetFunList'));
const OrganizationList = lazy(() => import('./views/organization/organization'));
const GetOrganizationList = lazy(() => import('./views/organization/GetOrganizationList'));
const EditOrganization = lazy(() => import('./views/organization/EditOrganization'));
const GetOrganizationListDetails = lazy(() => import('./views/organization/GetOrganizationListDetails'));
const GetFunListDetails = lazy(() => import('./views/fun/GetFunListDetails'));
const ProfessionalList = lazy(() => import('./views/professional/Professional'));
const GetProfessionalList = lazy(() => import('./views/professional/GetProfessionalList'));
const GetProfessionalListDetails = lazy(() => import('./views/professional/GetProfessionalListDetails'));
const ReligiousList = lazy(() => import('./views/religious/religious'));
const GetReligiousListDetails = lazy(() => import('./views/religious/GetReligiousListDetails'));
const ResourceList = lazy(() => import('./views/resource/resource'));
const GetResourceListDetails = lazy(() => import('./views/resource/GetResourceListDetails'));
const EditEvents = lazy(() => import('./views/events/EditEvents'));
const EditFun = lazy(() => import('./views/fun/EditFun'));
const EditProfessional = lazy(() => import('./views/professional/EditProfessional'));
const EditNews = lazy(() => import('./views/news/EditNews'));
const GetNewsListDetails = lazy(() => import('./views/news/GetNewsListDetails'));
const EditReligious = lazy(() => import('./views/religious/EditReligious'));
const Charts = React.lazy(() => import("./views/charts/Charts"));

// Containers
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));
const TheLayout = React.lazy(() => import("./containers/TheLayout"));
const Verify = React.lazy(() => import("./views/pages/auth/Verify"));
const ReVerify = React.lazy(() => import("./views/pages/auth/ReVerify"));
const Profile = React.lazy(() => import("./views/users/Profile"));
const Ads = React.lazy(() => import("./views/ads/ads"));
const AddAds = React.lazy(() => import("./views/ads/addAds"));
const EditAds = React.lazy(() => import("./views/ads/EditAds"));
const AdsSettings = React.lazy(() => import("./views/ads/AdsSettings"));
const Checkout = React.lazy(() => import("./views/ads/checkout"));
const PaymentSuccess = React.lazy(() => import("./views/ads/PaymentSuccess"));
const AdsDetails = React.lazy(() => import("./views/ads/AdsDetails"));
const Test = React.lazy(() => import("./NewTest.js"));

export default function Routes(props) {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Switch>
      {/* Test */}
      <Route exact path="/Charts" component={Charts} />
      <Route exact path="/Testing" component={Testing} />
      <Route exact path="/" component={Index} />
      <Route exact path="/business" component={Business} />
      <GuardedRoute exact path="/business/add" component={BusinessForm} auth={isLoggedIn}/>
      <Route exact path="/business/:id" component={Businessdetail} />
      
      <Route exact path="/news" component={GoodNews} />
      <GuardedRoute exact path="/news/add" component={GoodNewsForm} auth={isLoggedIn}/>
      <Route exact path="/news/:id" component={GoodNewsDetail} />
     
       
      <Route exact path="/test" component={Test} />

      <Route
        exact
        path="/faqs"
        component={Faqs}
      />

      <Route
        exact
        path="/terms-and-conditions"
        component={TermsAndConditions}
      />

    <Route
        exact
        path="/privacy-policy"
        component={PrivacyPolicy}
      />

      <Route
        exact
        path="/cookies"
        component={Cookies}
      />

      <Route
        exact
        path="/about"
        component={AboutUs}
      />

      <Route
        exact
        path="/blogs"
        component={Blogs}
      />

      <Route
        exact
        path="/contact-us"
        component={Contact}
      />

      <Route
        exact
        path="/hotels-and-restaurants"
        component={HotelsAndRestaurants}
      />
      <GuardedRoute
        exact
        path="/hotels-and-restaurants/add"
        component={HotelsAndRestaurantsForm} auth={isLoggedIn}
      />
      <Route
        exact
        path="/hotels-and-restaurants/:id"
        component={HotelsAndRestaurantsInner}
      />

      <Route exact path="/religious" component={Religious} />
      <GuardedRoute exact path="/religious/add" component={ReligiousForm} auth={isLoggedIn} />
      <Route exact path="/religious/:id" component={ReligiousDetail} />

      <Route exact path="/resources" component={Resources} />
      <GuardedRoute exact path="/resources/add" component={ResourcesForm} auth={isLoggedIn}/>
      <Route exact path="/resources/:id" component={ResourceDetail} />

      <Route exact path="/events" component={Events} />
      <GuardedRoute exact path="/events/add" component={EventsForm} auth={isLoggedIn}/>
      <Route exact path="/events/:id" component={EventsDetail} />
      

      

      <Route exact path="/fun-and-talents" component={FunAndTalents} />
      <GuardedRoute exact path="/fun-and-talents/add" component={FunAndTalentsForm} auth={isLoggedIn} />
      <Route exact path="/fun-and-talents/:id" component={FunAndTalentDetail} />

      <Route
        exact
        path="/organization-and-clubs"
        component={OrganizationAndClubs}
      />
      <GuardedRoute
        exact
        path="/organization-and-clubs/add"
        component={OrganizationAndClubsForm}
        auth={isLoggedIn}
      />
      <Route
        exact
        path="/organization-and-clubs/:id"
        component={OrganizationAndClubDetail}
      />

      <Route
        exact
        path="/professional-and-home-repairs"
        component={ProfessionalAndHomeRepairs}
      />
      <GuardedRoute
        exact
        path="/professional-and-home-repairs/add"
        component={ProfessionalAndHomeRepairsForm}
        auth={isLoggedIn}
      />
      <Route
        exact
        path="/professional-and-home-repairs/:id"
        component={ProfessionalDetail}
      />

      <Route
        exact
        path="/discussion-and-forum"
        component={DiscussionAndForum}
      />
      <GuardedRoute
        exact
        path="/discussion-and-forum/add"
        component={DiscussionAndForumForm}
        auth={isLoggedIn}
      />

      <Route exact path="/verify-email" component={Verify} />
      <Route exact path="/re-verifyemail" component={ReVerify} />

      <Route
        exact
        path="/login"
        component={Login}
        render={(props) => <Dashboard {...props} />}
      />

      <Route exact path="/search" component={Search} />

      <Route exact path="/register" component={Register} />

      <Route exact path="/forgot-password" component={ForgotPassword} />

      <Route exact path="/reset-password/confirm" component={ResetPassword} />
      <TheLayout>
        <GuardedRoute
          exact
          path="/dashboard"
          component={Dashboard}
          auth={isLoggedIn}
        />

        <GuardedRoute
          exact
          path="/change-password/"
          component={ChanageOldPassword}
          auth={isLoggedIn}
        />
        <GuardedRoute
          exact
          path="/add-ads/"
          component={Dashboard}
          auth={isLoggedIn}
        />
        

        <GuardedRoute exact path="/ads" component={Ads} auth={isLoggedIn} />
        <GuardedRoute exact path="/ads/add" component={AddAds} auth={isLoggedIn} />
        <GuardedRoute exact path="/ads/edit/:id" component={EditAds} auth={isLoggedIn} />
        <GuardedRoute exact path="/ads/settings" component={AdsSettings} auth={isLoggedIn} />
        <GuardedRoute exact path="/ads/checkout" component={Checkout} auth={isLoggedIn} />
        <GuardedRoute exact path="/ads/payment/success" component={PaymentSuccess} auth={isLoggedIn} />
        <GuardedRoute exact path="/ads/details/:id" component={AdsDetails} auth={isLoggedIn} />

        <GuardedRoute exact path="/users" component={Users} auth={isLoggedIn} />
        <GuardedRoute exact path="/user/edit/:id" component={EditUser} auth={isLoggedIn} />
        <GuardedRoute exact path="/dashboard/news" component={NewsDashList}  auth={isLoggedIn}/>

        <GuardedRoute
          exact
          path="/user/:id"
          component={User}
          auth={isLoggedIn}
        />

        <GuardedRoute
          exact
          path="/profile"
          component={Profile}
          auth={isLoggedIn}
        />

        <GuardedRoute
          exact
          path="/payments"
          component={payments}
          auth={isLoggedIn}
        />

        <GuardedRoute
          exact
          path="/dashboard/business"
          exact
          component={BusinessList}
          auth={isLoggedIn}
        />
        <GuardedRoute
          exact
          path="/dashboard/business/details"
          exact
          component={GetBusinessListDetails}
          auth={isLoggedIn}
        />
        <GuardedRoute
          exact
          path="/dashboard/business/edit/:id"
          exact
          component={EditBusiness}
          auth={isLoggedIn}
        />
         <GuardedRoute
          exact
          path="/dashboard/hotels"
          exact
          component={HotelsList}
          auth={isLoggedIn}
        />
        <GuardedRoute
          exact
          path="/dashboard/hotels/edit/:id"
          exact
          component={EditHotels}
          auth={isLoggedIn}
        />
         <GuardedRoute
          exact
          path="/dashboard/hotels/details"
          exact
          component={GetHotelsListDetails}
          auth={isLoggedIn}
        />

        {/*Events**/}
        <GuardedRoute
          exact
          path="/dashboard/events"
          exact
          component={EventsList}
          auth={isLoggedIn}
        />
         {/* <GuardedRoute
          exact
          path="/dashboard/hotels/edit"
          exact
          component={EditEvents}
          auth={isLoggedIn}
        /> */}
         <GuardedRoute
          exact
          path="/dashboard/events/details"
          exact
          component={GetEventsListDetails}
          auth={isLoggedIn}
        />
        <GuardedRoute
          exact
          path="/dashboard/events/edit/:id"
          exact
          component={EditEvents}
          auth={isLoggedIn}
        />

        {/****NEws********/}
        <GuardedRoute
          exact
          path="/dashboard/news/edit/:id"
          exact
          component={EditNews}
          auth={isLoggedIn}
        />
        <GuardedRoute
          exact
          path="/dashboard/news/details"
          exact
          component={GetNewsListDetails}
          auth={isLoggedIn}
        />


        {/********Fun and talents *******/}
        <GuardedRoute
          exact
          path="/dashboard/fun"
          exact
          component={FunList}
          auth={isLoggedIn}
        />
        <GuardedRoute
          exact
          path="/dashboard/fun/details"
          exact
          component={GetFunListDetails}
          auth={isLoggedIn}
        />
        <GuardedRoute
          exact
          path="/dashboard/fun/edit/:id"
          exact
          component={EditFun}
          auth={isLoggedIn}
        />

        {/*****Organizations */}
        <GuardedRoute
          exact
          path="/dashboard/organization"
          exact
          component={OrganizationList}
          auth={isLoggedIn}
        />
        <GuardedRoute
          exact
          path="/dashboard/organization/details"
          exact
          component={GetOrganizationListDetails}
          auth={isLoggedIn}
        />
        <GuardedRoute
          exact
          path="/dashboard/organization/edit/:id"
          exact
          component={EditOrganization}
          auth={isLoggedIn}
        />

          {/*****Professional */}
          <GuardedRoute
          exact
          path="/dashboard/professionals"
          exact
          component={ProfessionalList}
          auth={isLoggedIn}
        />
        <GuardedRoute
          exact
          path="/dashboard/professionals/details"
          exact
          component={GetProfessionalListDetails}
          auth={isLoggedIn}
        />
        <GuardedRoute
          exact
          path="/dashboard/professionals/edit/:id"
          exact
          component={EditProfessional}
          auth={isLoggedIn}
        />

        {/******religious */}
        {/*****Professional */}
        <GuardedRoute
          exact
          path="/dashboard/religious"
          exact
          component={ReligiousList}
          auth={isLoggedIn}
        />
        <GuardedRoute
          exact
          path="/dashboard/religious/details"
          exact
          component={GetReligiousListDetails}
          auth={isLoggedIn}
        />
        <GuardedRoute
          exact
          path="/dashboard/religious/edit/:id"
          exact
          component={EditReligious}
          auth={isLoggedIn}
        />
        {/*****resource */}
        <GuardedRoute
          exact
          path="/dashboard/resource"
          exact
          component={ResourceList}
          auth={isLoggedIn}
        />
        <GuardedRoute
          exact
          path="/dashboard/resource/details"
          exact
          component={GetResourceListDetails}
          auth={isLoggedIn}
        />
        <GuardedRoute
          exact
          path="/dashboard/resource/edit/:id"
          exact
          component={EditResource}
          auth={isLoggedIn}
        />
       
      </TheLayout>
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}