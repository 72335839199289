import {getUserProfile} from '../api'
import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext();



const AuthContextProvider = (props) => {

    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('usb__jwttoken') ? true : false);
    const [loggedInUser, setLoggedInUser ] = useState('');
    const [isDataManipulated, setIsDataManipulated ] = useState(true);

    useEffect(() => {
        const fetchedData = async()=>{
            if(isLoggedIn === true){
                const res = await getUserProfile();
                setLoggedInUser(res.data);
            }
            setIsDataManipulated(false);
        };
        if(isDataManipulated){
            fetchedData();
        }
    }, [isLoggedIn,isDataManipulated])

    return (
        <AuthContext.Provider value={{ isLoggedIn,loggedInUser, setLoggedInUser, isDataManipulated, setIsDataManipulated }}>
            {props.children}
        </AuthContext.Provider>
    )

}

 
export default AuthContextProvider;