import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import UseGeoLocation from "../hooks/UseGeoLocation";

export const GeoLocationContext = createContext();

const GeoLocationProvider = (props) => {

	const location = UseGeoLocation();
    const [userAddress, setUserAddress] = useState({
        state: '',
        city: '',
        country: ''
    })

        useEffect(() => {
        	(async()=>{
    	    	if(location.coordinates){
    	    		try{
                        const results = await getGeoAddress();
                        const city = results.data.results[0].address_components.filter((obj) => {
                          return obj.types.includes('locality');
                        })[0].long_name;
                        const state = results.data.results[0].address_components.filter((obj) => {
                          return obj.types.includes('administrative_area_level_1');
                        })[0].long_name;
                        const country = results.data.results[0].address_components.filter((obj) => {
                          return obj.types.includes('country');
                        })[0].long_name;
                        setUserAddress({...userAddress, city: city, state: state, country: country})
                    }catch(error){
                        // console.log(error)
                    }

    	    	}
        	})()
        }, [location.coordinates])

    console.log(userAddress && userAddress.country)

    function getGeoAddress() {
	  return axios.get(
	    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.coordinates && location.coordinates.lat && location.coordinates.lat},${location.coordinates && location.coordinates.lng && location.coordinates.lng}&key=AIzaSyBd5eujfslysDhbJ2pjOO3_VcScVtlA2Dw`
	  );
	}

    return (
        <GeoLocationContext.Provider value={{ userAddress }}>
            {props.children}
        </GeoLocationContext.Provider>
    )

}

 
export default GeoLocationProvider;