import axios from "axios";

// Auth

function signup(formData) {
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/users/`,
    formData,
    axiosConfig
  );
}

function forgotPassword(formData) {
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/password-reset/`,
    formData
  );
}

function resetPassword(formData) {
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/password-reset/confirm/`,
    formData
  );
}

function changePassword(Id, formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/api/change-password/${Id}/`,
    formData,
    axiosConfig
  );
}

function verifyEmail(verificationToken) {
  return axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/verify-email/`, {
    params: {
      token: verificationToken,
    },
  });
}

function reVerifyEmail(formData) {
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/re-verifyemail/`,
    formData
  );
}

// End of Auth

// Users

function listUsers(params) {
  const token = localStorage.getItem("usb__jwttoken");
  return axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/users/active/`, {
    headers: {
      authorization: `Token ${token}`,
    },
    params: params,
  });
}

function listAllUsers(params) {
  const token = localStorage.getItem("usb__jwttoken");
  return axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/users/`, {
    headers: {
      authorization: `Token ${token}`,
    },
    params: params,
  });
}

function getUser(Id) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/users/${Id}/`,
    axiosConfig
  );
}

function updateUser(Id, formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/api/users/${Id}/`,
    formData,
    axiosConfig
  );
}

function softDeleteUser(Id) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/users/${Id}/soft/`,
    axiosConfig
  );
}

// End of Users


function createNews(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/goodnews/`,
    formData,
    axiosConfig
  );
}



/**********REligious */
function createReligious(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/religious/`,
    formData,
    axiosConfig
  );
}

function createReligiousType(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/religious_type/`,
    formData,
    axiosConfig
  );
}

function deleteReligiousType(Id) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/api/religious_type/${Id}/`,
    axiosConfig
  );
}

function getReligiousType() {
  return axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/religious_type/`);
}



function listReligious(params) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: params
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/religious/`,
    axiosConfig
  );
}

function getSelfReligiousPost(params) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: params
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/religious/own_post/`,
    axiosConfig
  );
}

function updateReligious(Id, formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/api/religious/${Id}/`,
    formData,
    axiosConfig
  );
}


function retrieveReligious(Id) {
  return axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/religious/${Id}/`);
}


function getVerifiedReligious(params) {
  const axiosConfig = {
    params: params,
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/religious/verified/`, axiosConfig
  );
}

function createReligiousGallery(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/religiousgallary/`,
    formData,
    axiosConfig
  );
}

function selfReligiousGallery(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/religiousgallary/own_gallery/`,
    axiosConfig
  );
}

function deleteReligiousGallery(Id) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/api/religiousgallary/${Id}/`,
    axiosConfig
  );
}


function retrieveReligiousGallery(Id) {
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/religiousgallary/${Id}/particulargallery/`
  );
}
//End of religious






function createResources(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/jobpost/`,
    formData,
    axiosConfig
  );
}


function getVerifiedResource() {
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/jobpost/verified/`
  );
}




function updateResource(Id, formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/api/jobpost/${Id}/`,
    formData,
    axiosConfig
  );
}




/****************Job posting */





/*******Organization */
function listOrganization(params) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
     params: params,
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/organization/`,
    axiosConfig
  );
}


function retrieveOrganization(Id) {
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/organization/${Id}/`
  );
}


function updateOrganization(Id, formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/api/organization/${Id}/`,
    formData,
    axiosConfig
  );
}



function getVerifiedOrganization(params) {
  const axiosConfig = {
    params: params,
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/organization/verified/`, axiosConfig
  );
}



function getUnVerifiedOrganization() {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/organization/unverified/`,
    axiosConfig
  );
}

function getOwnOrganizationPost() {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/organization/unverified/`,
    axiosConfig
  );
}


function getSelfOrganizationPost(params) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
     params: params,
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/organization/own_post/`,
    axiosConfig
  );
}

function createOrganization(formData) {
  console.log(formData);
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/organization/`,
    formData,
    axiosConfig
  );
}

function createOrganizationGallery(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/organizationgallary/`,
    formData,
    axiosConfig
  );
}

function selfOrganizationGallery(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/organizationgallary/own_gallery/`,
    axiosConfig
  );
}

function deleteOrganizationGallery(Id) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/api/organizationgallary/${Id}/`,
    axiosConfig
  );
}


function retrieveOrganizationGallery(Id) {
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/organizationgallary/${Id}/particulargallery/`
  );
}



//End of organization

// Business

function listBusiness(params) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: params,
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/business/`,
    axiosConfig
  );
}

function retrieveBusiness(Id) {
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/business/${Id}/`
  );
}

function retrieveBusinessGallery(Id) {
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/businessgallary/${Id}/particulargallery/`
  );
}


function updateBusiness(Id, formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/api/business/${Id}/`,
    formData,
    axiosConfig
  );
}

function getVerifiedBusiness(params) {
  const axiosConfig = {
    params: params,
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/business/verified/`, axiosConfig
  );
}

function getUnVerifiedBusiness() {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/business/unverified/`,
    axiosConfig
  );
}

function getOwnBusinessPost() {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/business/unverified/`,
    axiosConfig
  );
}

function getSelfBusinessPost(params) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: params,
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/business/own_post/`,
    axiosConfig
  );
}

function createBusinessType(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/business_type/`,
    formData,
    axiosConfig
  );
}

function deleteBusinessType(Id) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/api/business_type/${Id}/`,
    axiosConfig
  );
}

function getBusinessType() {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/business_type/`);
}

function createBusiness(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/business/`,
    formData,
    axiosConfig
  );
}

function selfBusinessGallery(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/businessgallary/own_gallery/`,
    axiosConfig
  );
}

function createBusinessGallery(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/businessgallary/`,
    formData,
    axiosConfig
  );
}

function deleteBusinessGallery(Id) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/api/businessgallary/${Id}/`,
    axiosConfig
  );
}

// End of Business

//Start of hotel
function createHotel(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/hotel/`,
    formData,
    axiosConfig
  );
}


function listHotel(params) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: params
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/hotel/`,
    axiosConfig
  );
}

function getSelfHotelPost(params) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: params
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/hotel/own_post/`,
    axiosConfig
  );
}


function retrieveHotel(Id) {
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/hotel/${Id}/`
  );
}



function updateHotel(Id, formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/api/hotel/${Id}/`,
    formData,
    axiosConfig
  );
}

function getOwnHotelPost() {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/hotel/unverified/`,
    axiosConfig
  );
}



function getVerifiedHotel(params) {
  const axiosConfig = {
    params: params,
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/hotel/verified/`, axiosConfig
  );
}


function selfHotelGallery(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/hotelgallary/own_gallery/`,
    axiosConfig
  );
}

function createHotelGallery(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/hotelgallary/`,
    formData,
    axiosConfig
  );
}

function deleteHotelGallery(Id) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/api/hotelgallary/${Id}/`,
    axiosConfig
  );
}


function retrieveHotelGallery(Id) {
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/hotelgallary/${Id}/particulargallery/`
  );
}



//End of hotel





/******Resource */

function listResource(params) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: params
  };
  return axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/jobpost/`,axiosConfig);
}

function retrieveResource(Id) {
  return axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/jobpost/${Id}/`);
}

function getSelfResources(params) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: params
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/jobpost/own_post/`,
    axiosConfig
  );
}

// function updateResource(Id, formData) {
//   const token = localStorage.getItem("usb__jwttoken");
//   const axiosConfig = {
//     headers: {
//       Authorization: `Token ${token}`,
//     },
//   };
//   return axios.get(
//     `${process.env.REACT_APP_API_BASE_URL}/api/resources/${Id}/`,
//     formData,
//     axiosConfig
//   );
// }


//Events
function createEvents(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/events/`,
    formData,
    axiosConfig
  );
}

// function listEvents() {
//   return axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/events/`);
// }


function listEvents(params) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: params
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/events/`,
    axiosConfig
  );
}
function getSelfEventsPost(params) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: params
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/events/own_post/`,
    axiosConfig
  );
}

function retrieveEvents(Id) {
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/events/${Id}/`
  );
}

function updateEvents(Id, formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/api/events/${Id}/`,
    formData,
    axiosConfig
  );
}

function getOwnEventsPost() {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/events/unverified/`,
    axiosConfig
  );
}

function getVerifiedEvents(params) {
  const axiosConfig = {
    params: params,
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/events/published/`,
    axiosConfig
  );
}

function selfEventsGallery(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/eventgallary/own_gallery/`,
    axiosConfig
  );
}

function createEventsGallery(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/eventgallary/`,
    formData,
    axiosConfig
  );
}

function deleteEventsGallery(Id) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/api/eventgallary/${Id}/`,
    axiosConfig
  );
}

function retrieveEventsGallery(Id) {
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/eventgallary/${Id}/particulargallery/`
  );
}



// fun and talents
function createFunTalent(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/funtalent/`,
    formData,
    axiosConfig
  );
}





function listFuntalent(params) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: params
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/funtalent/`,
    axiosConfig
  );
}


function retrieveFuntalent(Id) {
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/funtalent/${Id}/`
  );
}

function getSelfFunPost(params) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: params
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/funtalent/own_post/`,
    axiosConfig
  );
}

function getVerifiedFuntalent() {
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/funtalent/verified/`
  );
}

function getOwnFunPost() {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/funtalent/unverified/`,
    axiosConfig
  );
}
function updateFuntalent(Id, formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/api/funtalent/${Id}/`,
    formData,
    axiosConfig
  );
}

function listGoodNews(params) {
  const axiosConfig = {
    params: params
  };
  return axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/goodnews/`, axiosConfig);
}

function retrieveGoodnews(Id) {
  return axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/goodnews/${Id}/`);
}
function updateNews(Id, formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/api/goodnews/${Id}/`,
    formData,
    axiosConfig
  );
}

function getSelfNewsPost(params) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: params
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/goodnews/own_post/`,
    axiosConfig
  );
}

function getVerifiedNews() {
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/goodnews/published/`
  );
}






/*******start of Professional */

function createProfessional(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/professional/`,
    formData,
    axiosConfig
  );
}

function listProfessional(params) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: params
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/professional/`,
    axiosConfig
  );
}


function createProfessionalType(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/professional_type/`,
    formData,
    axiosConfig
  );
}

function deleteProfessionalType(Id) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/api/professional_type/${Id}/`,
    axiosConfig
  );
}

function getProfessionalType() {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/professional_type/`, axiosConfig);
}




function retrieveProfessional(Id) {
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/professional/${Id}/`
  );
}


function getUserProfile() {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/users/profile/`,
    axiosConfig
  );
}
function getSelfProfessionalPost(params) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: params
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/professional/own_post/`,
    axiosConfig
  );
}


function getVerifiedProfessional(params) {
  const axiosConfig = {
    params: params,
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/professional/verified/`, axiosConfig
  );
}


function updateProfessional(Id, formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/api/professional/${Id}/`,
    formData,
    axiosConfig
  );
}

function createProfessionalGallery(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/professionalgallary/`,
    formData,
    axiosConfig
  );
}

function selfProfessionalGallery(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/professionalgallary/own_gallery/`,
    axiosConfig
  );
}

function deleteProfessionalGallery(Id) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/api/professionalgallary/${Id}/`,
    axiosConfig
  );
}


function retrieveProfessionalGallery(Id) {
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/professionalgallary/${Id}/particulargallery/`
  );
}
//End of professional



// Ads
function getAdZones() {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/zone/`,
    axiosConfig
  );
}

function createAdZone(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/zone/`,
    formData,
    axiosConfig
  );
}

function deleteAdZone(Id) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/api/zone/${Id}/`,
    axiosConfig
  );
}

function getAdsDuration() {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/time/`,
    axiosConfig
  );
}

function createAdsDuration(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/time/`,
    formData,
    axiosConfig
  );
}

function deleteAdsDuration(Id) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/api/time/${Id}/`,
    axiosConfig
  );
}

function getAdsPricing(params) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: params,
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/pricing/`,
    axiosConfig
  );
}

function createAdsPricing(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/pricing/`,
    formData,
    axiosConfig
  );
}

function retrieveAdsPricing(Id) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/pricing/${Id}/`,
    axiosConfig
  );
}

function deleteAdsPricing(Id) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/api/pricing/${Id}/`,
    axiosConfig
  );
}

function createAd(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/ads/`,
    formData,
    axiosConfig
  );
}

function listAllAds(params) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: params,
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/ads/`,
    axiosConfig
  );
}

function listUserAds(params) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: params,
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/ads/userads/`,
    axiosConfig
  );
}

function retrieveAd(Id) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    }
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/ads/${Id}/`,
    axiosConfig
  );
}

function updateAd(formData, Id) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    }
  };
  return axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/api/ads/${Id}/`,
    formData,
    axiosConfig
  );
}

function deleteAd(Id) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    }
  };
  return axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/api/ads/${Id}/`,
    axiosConfig
  );
}

function listActiveAds() {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      is_published: true
    }
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/ads/`,
    axiosConfig
  );
}

function listInActiveAds() {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      is_published: false
    }
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/ads/`,
    axiosConfig
  );
}

function listPaidAds() {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      is_paid: true
    }
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/ads/`,
    axiosConfig
  );
}

function getPayment() {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/ads/payment/`,
    axiosConfig
  );
}

function postPaymentVerfication(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/payment/`,
    formData,
    axiosConfig
  );
}


function postSearchData(formData) {
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/search/`,
    formData
    
  );
}

function verifyPayment(Id) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/ads/${Id}/payment/`,
    axiosConfig
  );
}

function getPaymentHistory(params) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: params
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/payment/`,
    axiosConfig
  );
}

function getSelfPaymentHistory(params) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: params
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/payment/userpaymenthistory/`,
    axiosConfig
  );
}

function getPaymentHistoryDashboard(params) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      limit: 5
    }
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/payment/`,
    axiosConfig
  );
}

function getPaymentHistoryUserDashboard(params) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      limit: 5
    }
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/payment/userpaymenthistory/`,
    axiosConfig
  );
}

function getNormalUserWidgetData() {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    }
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/normal-widget-data/`,
    axiosConfig
  );
}

function getAdminUserWidgetData() {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    }
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/admin-widget-data/`,
    axiosConfig
  );
}

function listPublicAds(params) {
  const axiosConfig = {
    params: params
  };
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/ads/publicads/`,
    axiosConfig
  );
}

function filteredPublicAds(formData) {
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/publicads/`,
    formData
  );
}

function listVideoAds() {
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/videoads/`
  );
}

function postVideoAds(formData) {
  const token = localStorage.getItem("usb__jwttoken");
  const axiosConfig = {
    headers: {
      Authorization: `Token ${token}`,
    }
  };
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/videoads/`,
    formData,
    axiosConfig
  );
}

export {
  signup,
  updateUser,
  createHotel,
  updateHotel,
  getVerifiedHotel,
  listHotel,
  retrieveHotel,
  getSelfHotelPost,
  getOwnHotelPost,
  selfHotelGallery,
  createHotelGallery,
  deleteHotelGallery,
  createNews,
  createReligious,
  getReligiousType,
  listReligious,
  getSelfReligiousPost,
  retrieveReligious,
  createReligiousType,
  deleteReligiousType,
  getVerifiedReligious,
  updateReligious,
  getSelfResources,
  createResources,
  createOrganization,
  updateOrganization,
  getVerifiedOrganization,
  getOwnOrganizationPost,
  getUnVerifiedOrganization,
  createBusiness,
  selfBusinessGallery,
  createBusinessGallery,
  deleteBusinessGallery,
  listBusiness,
  retrieveBusiness,
  listResource,
  retrieveResource,
  getVerifiedResource,
  updateResource,
  updateEvents,
  listEvents,
  createEvents,
  getSelfEventsPost,
  getOwnEventsPost,
  retrieveEvents,
  selfEventsGallery,
  createEventsGallery,
  deleteEventsGallery,
  forgotPassword,
  resetPassword,
  listGoodNews,
  retrieveGoodnews,
  getVerifiedNews,
  createFunTalent,
  listFuntalent,
  retrieveFuntalent,
  updateFuntalent,
  getVerifiedFuntalent,
  getOwnFunPost,
  getSelfFunPost,
  getSelfOrganizationPost,
  deleteOrganizationGallery,
  updateProfessional,
  listOrganization,
  retrieveOrganization,
  createOrganizationGallery,
  selfOrganizationGallery,
  listProfessional,
  createProfessionalType,
  deleteProfessionalType,
  getVerifiedProfessional,
  getVerifiedEvents,
  retrieveProfessional,
  getSelfProfessionalPost,
  createProfessionalGallery,
  selfProfessionalGallery,
  deleteProfessionalGallery,
  retrieveProfessionalGallery,
  getUserProfile,
  changePassword,
  verifyEmail,
  reVerifyEmail,
  listUsers,
  listAllUsers,
  getUser,
  softDeleteUser,
  createBusinessType,
  getBusinessType,
  deleteBusinessType,
  getVerifiedBusiness,
  getUnVerifiedBusiness,
  getOwnBusinessPost,
  getSelfBusinessPost,
  updateBusiness,
  retrieveBusinessGallery,
  createProfessional,
  getAdZones,
  createAdZone,
  deleteAdZone,
  getAdsDuration,
  createAdsDuration,
  deleteAdsDuration,
  getAdsPricing,
  createAdsPricing,
  retrieveAdsPricing,
  deleteAdsPricing,
  getSelfNewsPost,
  createAd,
  listAllAds,
  listUserAds,
  retrieveAd,
  updateAd,
  deleteAd,
  listActiveAds,
  listInActiveAds,
  listPaidAds,
  getPayment,
  postPaymentVerfication,
  postSearchData,
  verifyPayment,
  getProfessionalType,
  getPaymentHistory,
  getSelfPaymentHistory,
  getPaymentHistoryDashboard,
  updateNews,
  getPaymentHistoryUserDashboard,
  getNormalUserWidgetData,
  getAdminUserWidgetData,
  listPublicAds,
  filteredPublicAds,
  retrieveEventsGallery,
  retrieveOrganizationGallery,
  retrieveHotelGallery,
  listVideoAds,
  postVideoAds,
  createReligiousGallery,
  selfReligiousGallery,
  deleteReligiousGallery,
  retrieveReligiousGallery
};
