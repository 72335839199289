import React, { createContext,useState,useEffect } from 'react';
import { listAllUsers,listActiveAds,listInActiveAds,listPaidAds,getPaymentHistoryDashboard } from "../api";
export const UserDataContext = createContext();

function UserDataProvider(props){

	const [userData, setUserData] = useState('');
	const [activeAdsData, setActiveAdsData] = useState('');
	const [inActiveAdsData, setInActiveAdsData] = useState('');
	const [paidAdsData, setPaidAdsData] = useState('');
	const [paymentAdsData, setPaymentAdsData] = useState('');
	const [isDataContentManipulated, setIsDataManipulated] = useState(true);

	useEffect(()=>{

		const fetchedData = async() => {
			const userDataResp = await listAllUsers();
			const activeAdsDataResp = await listActiveAds();
			const inActiveAdsDataResp = await listInActiveAds();
			const paidAdsDataResp = await listPaidAds();
			const paymentAdsDataResp = await getPaymentHistoryDashboard();
			setUserData(userDataResp.data);
			setActiveAdsData(activeAdsDataResp.data);
			setInActiveAdsData(inActiveAdsDataResp.data);
			setPaidAdsData(paidAdsDataResp.data);
			setPaymentAdsData(paymentAdsDataResp.data);
			setIsDataManipulated(false);
		};
		if(isDataContentManipulated){
			fetchedData();
		}

	},[isDataContentManipulated])

	return(
		<UserDataContext.Provider value={{ userData,activeAdsData,inActiveAdsData,paidAdsData,paymentAdsData,setIsDataManipulated }}>
			{props.children}
		</UserDataContext.Provider>
	)
}
export default UserDataProvider;