import React, { Suspense,Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import './scss/style.scss';
import Routes from './routes.js';

// Auth Context
import AuthContextProvider from "./contexts/Auth";
import GeoLocationProvider from "./contexts/GeoLocation";
import AdsDataContextProvider from "./contexts/AdsData";
import UserDataProvider from "./contexts/UserData";

const loading = (
  <div id="preloader">
    <div className="preloarder">
      <div className='SpanLoad'>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
)


function App() {
  return (
    <>
    <GeoLocationProvider>
      <AuthContextProvider>
            <BrowserRouter>
    	        <React.Suspense fallback={loading}>
    	            <Routes />
    	        </React.Suspense>
            </BrowserRouter>
      </AuthContextProvider>
    </GeoLocationProvider>
     <MessengerCustomerChat
        pageId="105261768565087"
        appId="404904831114380"
      />
    </>
  );
}

export default App;
